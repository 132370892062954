// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("data:image/svg+xml;charset=utf-8,%3Csvg width=%2741%27 height=%2726%27 xmlns=%27http://www.w3.org/2000/svg%27%3E%3Cpath fill=%27%23000%27 d=%27m0 5.382 19.983 19.983L40.14 5.208 34.932 0 19.869 15.062 4.84.032z%27 fill-rule=%27evenodd%27/%3E%3C/svg%3E", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._dropDown[data-v-4cc555db]{position:relative}._dropDown.active[data-v-4cc555db]{background:var(--active-color)}._dropDown.active ._caret[data-v-4cc555db]{transform:rotate(-180deg)}._caret[data-v-4cc555db]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-position:50% 55%;background-repeat:no-repeat;background-size:.75rem;transition:all .25s cubic-bezier(.19,1,.22,1)}._dropDown--content[data-v-4cc555db]{position:absolute;z-index:9999;left:0;display:flex;flex-flow:column nowrap;gap:2px;margin-top:2px;padding:2px;background:#fff;border-radius:4px;max-width:40ch;box-shadow:0 0 0 1px rgba(20,21,26,.05),0 .3px .4px rgba(20,21,26,.02),0 .9px 1.5px rgba(20,21,26,.045),0 3.5px 6px rgba(20,21,26,.09)}._dropDown--content[data-v-4cc555db] a,._dropDown--content[data-v-4cc555db] button{padding:calc(var(--spacing)/1.5) calc(var(--spacing)/1.5);text-overflow:ellipsis;white-space:nowrap;overflow:hidden;width:100%;text-align:left;border-radius:2px;transition:all .25s cubic-bezier(.19,1,.22,1)}._dropDown--content[data-v-4cc555db] a:active,._dropDown--content[data-v-4cc555db] a:focus-visible,._dropDown--content[data-v-4cc555db] a:hover,._dropDown--content[data-v-4cc555db] button:active,._dropDown--content[data-v-4cc555db] button:focus-visible,._dropDown--content[data-v-4cc555db] button:hover{background:var(--c-gris)}._dropDown--content_right[data-v-4cc555db]{left:auto;right:0}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
