// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("data:image/svg+xml;charset=utf-8,%3Csvg width=%2741%27 height=%2726%27 xmlns=%27http://www.w3.org/2000/svg%27%3E%3Cpath fill=%27%23000%27 d=%27m0 5.382 19.983 19.983L40.14 5.208 34.932 0 19.869 15.062 4.84.032z%27 fill-rule=%27evenodd%27/%3E%3C/svg%3E", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("data:image/svg+xml;charset=utf-8,%3Csvg width=%2741%27 height=%2726%27 xmlns=%27http://www.w3.org/2000/svg%27%3E%3Cpath fill=%27%23fff%27 d=%27m0 5.382 19.983 19.983L40.14 5.208 34.932 0 19.869 15.062 4.84.032z%27 fill-rule=%27evenodd%27/%3E%3C/svg%3E", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._statusTag[data-v-d2d26b06]{display:flex;flex-flow:row nowrap;align-items:center;font-variant:small-caps;text-transform:uppercase;font-weight:500;padding:0;background:transparent;--bg-color:transparent;--c-color:#000;--select-caret:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}._statusTag[data-status=finished][data-v-d2d26b06],._statusTag[data-status=private][data-v-d2d26b06]{--c-color:#fff;--bg-color:var(--c-noir);--select-caret:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}._statusTag[data-status=finished][data-v-d2d26b06]{--bg-color:var(--c-bleuvert)}._statusTag ._tag[data-v-d2d26b06],._statusTag select[data-v-d2d26b06]{background-color:var(--bg-color);color:var(--c-color)}._statusTag:where(button) ._tag[data-v-d2d26b06]:focus-visible,._statusTag:where(button) ._tag[data-v-d2d26b06]:hover{opacity:.8}._statusTag ._tag[data-v-d2d26b06]{display:flex;flex-flow:row nowrap;align-items:center;gap:calc(var(--spacing)/4);height:var(--input-height-small);padding:calc(var(--spacing)/4);border-radius:var(--input-border-radius);font-size:var(--sl-font-size-small)}._statusTag select[data-v-d2d26b06]{max-width:15ch;background-image:var(--select-caret)}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
