var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"_uploadFiles"},_vm._l((_vm.files_to_upload),function(f){return _c('div',{key:f.name,staticClass:"_uploadFile",class:_vm.cssStatus(f),style:(`--progress-percent: ${
      _vm.files_to_upload_meta[f.name].upload_percentages / 100
    }`)},[_c('div',{staticClass:"_uploadFile--progressBar"}),(
        !!f.type &&
        f.type.includes('image') &&
        _vm.files_to_upload_meta[f.name].status === 'sending'
      )?_c('img',{staticClass:"_uploadFile--image",attrs:{"width":"50","src":_vm.getImgPreview(f)}}):_c('div',{staticClass:"_uploadFile--image"}),_c('div',{staticClass:"_uploadFile--filename",attrs:{"title":f.name}},[_vm._v(_vm._s(f.name))]),_c('div',{staticClass:"_uploadFile--size"},[_vm._v(_vm._s(_vm.formatBytes(f.size)))]),_c('div',{staticClass:"_uploadFile--action"},[_c('button',{staticClass:"u-buttonLink",attrs:{"type":"button","disabled":_vm.files_to_upload_meta[f.name].status !== 'failed'},on:{"click":function($event){return _vm.sendThisFile(f)}}},[(!_vm.files_to_upload_meta.hasOwnProperty(f.name))?[_vm._v(" "+_vm._s(_vm.$t("import"))+" ")]:(_vm.files_to_upload_meta[f.name].status === 'waiting')?[_vm._v(" "+_vm._s(_vm.$t("waiting"))+" ")]:(_vm.files_to_upload_meta[f.name].status === 'sending')?[_vm._v(" "+_vm._s(_vm.$t("sending"))+" ")]:(_vm.files_to_upload_meta[f.name].status === 'success')?[_vm._v(" "+_vm._s(_vm.$t("sent"))+" ")]:(_vm.files_to_upload_meta[f.name].status === 'failed')?[_vm._v(" "+_vm._s(_vm.$t("retry"))+" ")]:_vm._e()],2)])])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }