var render = function render(){var _vm=this,_c=_vm._self._c;return _c('BaseModal2',{attrs:{"title":_vm.$t('contributors'),"is_closable":_vm.is_closable},on:{"close":function($event){return _vm.$emit('close')}}},[_c('div',[(!_vm.connected_as || _vm.is_instance_admin)?[_c('RadioSwitch',{attrs:{"content":_vm.current_mode,"options":[
          {
            label: _vm.$t('login'),
            value: 'login',
          },
          {
            label: _vm.$t('create_account'),
            value: 'create',
          },
        ]},on:{"update:content":function($event){_vm.current_mode=$event}}}),_c('br'),(_vm.current_mode === 'login')?_c('LoginAs',{attrs:{"authors":_vm.sorted_authors},on:{"close":function($event){return _vm.$emit('close')}}}):(_vm.current_mode === 'create')?_c('CreateAuthor',{attrs:{"is_first_user":!_vm.authors || _vm.authors.length === 0},on:{"close":function($event){return _vm.$emit('close')}}}):_vm._e()]:_vm._e(),(_vm.connected_as && _vm.current_mode === 'login')?_c('fieldset',[_c('legend',{staticClass:"u-label"},[_vm._v(_vm._s(_vm.$t("your_account")))]),_c('AuthorCard',{key:_vm.connected_as.$path,staticClass:"u-spacingBottom",attrs:{"author":_vm.connected_as,"context":'preview'},on:{"navToPage":function($event){return _vm.$emit('close')}}}),_c('button',{staticClass:"u-button u-button_red",attrs:{"type":"button"},on:{"click":_vm.logout}},[_vm._v(" "+_vm._s(_vm.$t("logout"))+" ")])],1):_vm._e(),(_vm.current_mode === 'login')?[_c('br'),(_vm.authors.length === 0)?_c('small',[_vm._v(" "+_vm._s(_vm.$t("no_accounts_yet"))+" ")]):_vm._e(),_c('router-link',{staticClass:"u-buttonLink",attrs:{"to":'/@'},nativeOn:{"click":function($event){return _vm.$emit('close')}}},[_c('b-icon',{attrs:{"icon":"person-circle"}}),_vm._v(" "+_vm._s(_vm.$t("show_all_accounts"))+" ")],1)]:_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }